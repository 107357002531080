import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Download } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { DeleteDialog } from "./DeleteDialog";
import {
  Backdrop,
  Modal,
  Snackbar,
  TextField,
  Typography,
  Box,
  Fab,
  Fade,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  DialogTitle,
  Select,
  MenuItem,
  TablePagination,
  Switch
} from "@mui/material";

import formNames from "../config/formNames.json";

import MuiAlert from "@mui/material/Alert";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import {
  collection,
  doc,
  onSnapshot,
  query,
  setDoc,
  getDocs,
  where,
  or,
  and
} from "firebase/firestore";
import React, { useEffect, useState,forwardRef, useRef } from "react";

import PDFViewer from "./PDFViewer";
import useCustomParams from "./shared/useCustomParams";
import FormDuplicateButton from "./FormDuplicateButton";
import axios from "axios";



const Alert =forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const Forms = (props) => {
  const {
    db,
    user,
    groupId,
    isPro,
    allData,
    applicantDetails,
    ownerDetails,
    filingRepDetails,
    buildingDetails,
    extraFieldsToAutoFill,
    isInactive,
    showToggleFromBuilding
    // showJobBuildingForms,
    // showLabelBuildingForms,
    // showLabelBuildingJobsForms
  } = props;

const [fromBuildingShowFormsOfAssociatedJobs,setFromBuildingShowFormsOfAssociatedJobs]=useState(false)


const [downloadFromForm,setDownloadFromForm]=useState(false)

  const [feedbackMessage, setFeedbackMessage] = useState({
    open: false,
    message: '',
    severity: 'success'
  });


  const [Forms, setForms] = useState([]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 2,
  };

  const [filteredForms, setFilteredForms] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [isNewForm, setIsNewForm] = useState(false);

  const [newForm, setNewForm] = useState({
    fileName: "",
    formName: "",
    note: "",
  });

  const [editNote, setEditNote] = useState({
    note: "",
  });

  const openPDF = () => {};

  const copyApplication = (identifier) => {
    // write function to  copy application
    const thisForm = doc(props.db, "filings", identifier.row.formId);

    if (isInactive) {
      return;
    }

    const newForm = {
      ...identifier.row,
      dateCreated: Date.now(),
      dateUpdated: Date.now(),
    };
    delete newForm.id;
    props.db
      .collection("filings")
      .add({
        ...newForm,
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const deleteApplication = (identifier) => {
    setItemToDelete({
      itemId: identifier.row.formId,
      identifier: identifier.row.formName,
    });
    setDeleteOpen(true);
  };

  const [currentNote, setCurrentNote] = useState();

  const editNoteApplication = (identifier) => {
    setCurrentNote(identifier.row.note);
    setItemToEdit({
      itemId: identifier.row.formId,
      identifier: identifier.row.formName,
    });
    seteditNoteOpen(true);
  };

  const confirmDelete = async () => {
    db.collection("filings").doc(itemToDelete.itemId).delete();
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
    openFeedbackMessage('Form deleted successfully!', 'success');
    setForms((prevForms) =>
      prevForms.filter((form) => form.id !== itemToDelete.itemId)
    );

  };

  const [viewerOpen, setViewerOpen] = useState(false);

  const [newFormOpen, setNewFormOpen] = useState(false);
  const [editNoteOpen, seteditNoteOpen] = useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const [formToView, setFormToView] = useState({
    formId: "",
    formContent: "",
    formAnnotations: "",
    fileName: "",
  });

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [itemToEdit, setItemToEdit] = useState({
    itemId: "",
    identifier: "",
  });

  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = React.useState(false);






  const handleNewFormClose = () => {
    setNewFormOpen(false);
  };

  const handleEditNoteClose = () => {
    seteditNoteOpen(false);
  };

  const [failureOpen, setOpenFailure] = React.useState(false);

  const openSuccess = (message) => {
    setOpen(message || true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleChangeNewForm = (values) => {
    setNewForm({
      ...newForm,
      fileName: values.filename,
      formName: values.label,
    });
  };

  const handleChangeNewFormNote = (event) => {
    setNewForm({ ...newForm, note: event.target.value });
  };

  const handleChangeEditNot = (event) => {
    setCurrentNote(event.target.value);
    setEditNote({ note: event.target.value });
  };



//   useEffect(() => {
//     const fetchFilteredForms = async () => {
//       try {
    
//         // Query to filter forms where projectId is in the projectIds array
//         const q = query(collection(db, "filings"), where('projectId', 'in', projectIds));
       
//         // Execute the query and retrieve the documents
//         const querySnapshot = await getDocs(q);

//         // Map over the documents to get the data
//         const forms = querySnapshot.docs.map((doc) => ({
//           id: doc.id,
//           ...doc.data(),
//         }));
// console.log(forms)
//         // Set the filtered forms in state
//         setFormsTest(forms);
//         setForms((prev) => [...prev, ...forms]);
//       } catch (error) {
//         console.error("Error fetching filtered forms:", error);
//       }
//     };

//     fetchFilteredForms();
//   }, []); // Empty dependency array ensures this runs once on component mount




  // useEffect(async() => {
   
  //   var q,
  //     conditions =
  //       groupId && isPro && allData
  //         ? [
  //           or(
  //             where("userId", "==", props.userId),
  //             where("groupId", "==", props.groupId))
  //           ]
  //         : [where("userId", "==", props.userId)];
          
        

  //   if (props.buildingId) {
  //     q = query(
  //       collection(db, "filings"),
  //       ...conditions,
  //       where("buildingId", "==", props.buildingId)
  //     );
  //   }

  //   if (props.projectId) {
  //     q = query(
  //       collection(db, "filings"),
  //       ...conditions,
  //       where("projectId", "==", props.projectId)
  //     );
  //   }
   

 
  //   if(fromBuildingShowFormsOfAssociatedJobs){
  //     const projectsQuery = query(collection(db, "projects"), where("building", "==", props.buildingId));
  //     const projectsSnapshot = await getDocs(projectsQuery);
  //     const projectIds = projectsSnapshot.docs.map((doc) => doc.id);
      
  //     // q = query(
  //     //   collection(db, "filings"),
  //     //   ...conditions,
  //     //   where('projectId', 'in',projectIds)
  //     // );

  //    q = query(
  //     collection(db, "filings"),
  //    and(
  //      ...conditions, 
  //     or(
  //     where('projectId', 'in', projectIds),
  //     where("buildingId", "==", props.buildingId)
  //      )
  //      )
  //       );
     

  //    }



  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
    
  //     const newForms = querySnapshot.docs.map((doc) => {
  //       const data = doc.data();
      
  //       return {
  //         ...data,
  //         id: doc.id,
  //         flagged:data.buildingId=="" ? true:false
  //       };
  //     });
  //     setForms(newForms);
  //   });

  
  // }, [fromBuildingShowFormsOfAssociatedJobs]);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        let q;
        const conditions =
          groupId && isPro && allData
            ? [
                or(
                  where("userId", "==", props.userId),
                  where("groupId", "==", props.groupId)
                )
              ]
            : [where("userId", "==", props.userId)];
  
        if (props.buildingId) {
          q = query(
            collection(db, "filings"),
            ...conditions,
            where("buildingId", "==", props.buildingId)
          );
        }
  
        if (props.projectId) {
          q = query(
            collection(db, "filings"),
            ...conditions,
            where("projectId", "==", props.projectId)
          );
        }
  
        if (fromBuildingShowFormsOfAssociatedJobs) {
          const projectsQuery = query(
            collection(db, "projects"),
            where("building", "==", props.buildingId)
          );
          const projectsSnapshot = await getDocs(projectsQuery);
          const projectIds = projectsSnapshot.docs.map((doc) => doc.id);
  
          q = query(
            collection(db, "filings"),
            and(
              ...conditions,
              or(
                where("projectId", "in", projectIds),
                where("buildingId", "==", props.buildingId)
              )
            )
          );
        }
  
        if (!q) return;


        const querySnapshot = await getDocs(q);
        const newForms = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            id: doc.id,
            flagged: data.buildingId === "" ? true : false,
          };
        });
  
        setForms(newForms);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchData();
  }, [fromBuildingShowFormsOfAssociatedJobs,viewerOpen]); 
  
  

  // useEffect(() => {
  //   const fetchFilteredForms = async () => {
  //     try {
        
  //       const projectsQuery = query(collection(db, "projects"), where("building", "==", props.buildingId));
  //       const projectsSnapshot = await getDocs(projectsQuery);
  
       
  //       const projectIds = projectsSnapshot.docs.map((doc) => doc.id);
  
  //       // console.log("Project IDs:", projectIds);
  
  //       if (projectIds.length === 0) {
  //         console.log("No projects found for the given buildingId");
  //         setForms([]);
  //         return;
  //       }


  //      let conditions= groupId && isPro && allData
  //       ? [
  //           where("userId", "==", props.userId),
  //           where("groupId", "==", props.groupId),
  //         ]
  //       : [where("userId", "==", props.userId)];

      

  //       const formsQuery = query(collection(db, "filings"), where('projectId', 'in',projectIds ),...conditions);
  //       const formsSnapshot = await getDocs(formsQuery);
       
       
  //       const forms = formsSnapshot.docs.map((doc) => ({
  //         id: doc.id,
  //         ...doc.data(),
  //         flagged:true
  //       }));
  
  //       // console.log("Forms:", forms);
  
        
        
  //       setForms((prev) => [...prev,...forms]);
  //     } catch (error) {
  //       console.error("Error fetching filtered forms:", error);
  //     }
  //   };

  // // if(showJobBuildingForms){
  // //   fetchFilteredForms();
  // // }
  //    if(fromBuildingShowFormsOfAssociatedJobs){
  //    fetchFilteredForms();
  //    }
  
  
  // }, [props.buildingId,fromBuildingShowFormsOfAssociatedJobs,allData]); 
  





  const columnDefs = isInactive
    ? [
      {
        field: "form",
        headerName: "Form type",
        flex: 2,
        headerClassName: "forms-header",
        ...(fromBuildingShowFormsOfAssociatedJobs
          ? {
              renderCell: (params) => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{ 
                      color: params.row.flagged ? "#002978" : "inherit",
                      fontWeight: params.row.flagged ? "bold" : "normal",
                     
                    }}
                  >
                    {params.value}
                  </span>
                </div>
              ),
            }
          : {}),
      },
       
        {
          field: "dateCreated",
          headerName: "Date created",
          flex: 1,
          headerClassName: "forms-header",
        },
        {
          field: "dateUpdated",
          headerName: "Date updated",
          flex: 1,
          headerClassName: "forms-header",
        },
        {
          field: "note",
          headerName: "Note",
          flex: 4,
          headerClassName: "forms-header",
        },
      ]
    : [
        // {
        //   field: "form",
        //   headerName: "Form type",
        //   flex: 2,
        //   headerClassName: "forms-header",
          
        //     ...(fromBuildingShowFormsOfAssociatedJobs
        //       ? {
        //           renderCell: (params) => (
                   
        //             <div
        //               style={{
        //                  position: "relative",
        //                 display: "flex",
        //                 alignItems: "center",
        //               }}
        //             >
                      
        //               <div
        //                 style={{
        //                    position: "absolute",
        //                   left: -4,
        //                   top: "17%",
        //                   transform: "translateY(-50%)",
        //                   width: "10px",
        //                   height: "10px",
        //                   borderRadius: "50%",
        //                   backgroundColor: "#002978",
        //                   display:params.row.flagged ?"block":"none"
                      
        //                 }}
        //               ></div>
        //               <span>{params.value}</span>
        //             </div>
        //           ),
        //         }
        //       : {}),
         
        // },
        {
          field: "form",
          headerName: "Form type",
          flex: 2,
          headerClassName: "forms-header",
          ...(fromBuildingShowFormsOfAssociatedJobs
            ? {
                renderCell: (params) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ 
                        color: params.row.flagged ? "#002978" : "inherit",
                        fontWeight: params.row.flagged ? "bold" : "normal",
                       
                      }}
                    >
                      {params.value}
                    </span>
                  </div>
                ),
              }
            : {}),
        },
        
        {
          field: "dateCreated",
          headerName: "Date created",
          flex: 1,
          headerClassName: "forms-header",
        },
        {
          field: "dateUpdated",
          headerName: "Date updated",
          flex: 1,
          headerClassName: "forms-header",
        },
        {
          field: "note",
          headerName: "Note",
          flex: 4,
          headerClassName: "forms-header",
        },
        {
          field: "actions",
          type: "actions",
          headerClassName: "forms-header",

          // getActions: (params) => [
          //   <GridActionsCellItem
          //   icon={<Download />}
          //    onClick={(e) =>{ setDownloadFromForm(true); handleOpenForm(params)} }
          //   label="Edit Note"
          // />,

          //   <GridActionsCellItem
          //     icon={<EditIcon />}
          //     onClick={() => editNoteApplication(params)}
          //     label="Edit Note"
          //   />,
          //   // <GridActionsCellItem
          //   //   icon={<FileCopyIcon />}
          //   //   onClick={() => copyApplication(params)}
          //   //   label="Copy"
          //   // />,
          //   <FormDuplicateButton
          //     formDetails={params}
          //     collection={props?.buildingId ? "buildings" : "projects"}
          //     forms={Forms}
          //     openSucess={openSuccess}
          //   />,
          //   <GridActionsCellItem
          //     icon={<Delete />}
          //     onClick={() => deleteApplication(params)}
          //     label="Delete"
          //   />,
          // ],
          // flex: 1,
          getActions: (params) => {
            const actions = [];

            actions.push(
              <GridActionsCellItem
                icon={<Download />}
                onClick={(e) => { setDownloadFromForm(true); handleOpenForm(params); }}
                label="Edit Note"
              />
            );

            actions.push(
              <GridActionsCellItem
                icon={<EditIcon />}
                onClick={() => editNoteApplication(params)}
                label="Edit Note"
              />
            );
           
            if (!fromBuildingShowFormsOfAssociatedJobs) {
              // actions.push(
              //   <GridActionsCellItem
              //     icon={<EditIcon />}
              //     onClick={() => editNoteApplication(params)}
              //     label="Edit Note"
              //   />
              // );
          
             
              actions.push(
                <FormDuplicateButton
                  formDetails={params}
                  collection={props?.buildingId ? "buildings" : "projects"}
                  forms={Forms}
                  openSucess={openSuccess}
                  setForms={setForms}
                  fromFormsOfBuilding={true}
                />
              );

              // actions.push(
              //   <GridActionsCellItem
              //     icon={<Delete />}
              //     onClick={() => deleteApplication(params)}
              //     label="Delete"
              //   />
              // );

            }
            actions.push(
              <GridActionsCellItem
                icon={<Delete />}
                onClick={() => deleteApplication(params)}
                label="Delete"
              />
            );
           
          
            return actions;
          },
          flex: 1,
          
        },
      ];

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
  };

  const handleNewForm = () => {
    setNewFormOpen(true);
  };

  const handleCopyForm = () => {
    setNewFormOpen(true);
  };

  const handleSave = (formContent, annotations, formId) => {
    const thisForm = doc(props.db, "filings", formId);
    setDoc(
      thisForm,
      {
        annotations: JSON.stringify(annotations),
        content: JSON.stringify(formContent),
      },
      { merge: true }
    )
      .then(() => {
        openSuccess();
        setLoading(false);

      })
      .catch((error) => {
        openFailure();
        setLoading(false);
      });
  };

  const handleOpenForm = (event) => {
    setFormToView({
      formId: event.row.formId,
      formContent: event.row.content,
      formAnnotations: event.row.annotations,
      fileName: event.row.fileName,
    });
    setViewerOpen(true);
  };

  const handleCloseViewer = () => {
    setViewerOpen(false);
  };

  const handleAddForm = () => {
    db.collection("filings")
      .add({
        ...newForm,
        form: newForm.formName,
        userId: props.userId,
        groupId: props.groupId,
        buildingId: props.buildingId ? props.buildingId : "",
        projectId: props.projectId ? props.projectId : "",
        dateCreated: Date.now(),
        dateUpdated: Date.now(),
      })
      .then((docRef) => {

        const addedForm = {
          id: docRef.id,
          form: newForm.formName,
          userId: props.userId,
          groupId: props.groupId,
          buildingId: props.buildingId ? props.buildingId : "",
          projectId: props.projectId ? props.projectId : "",
          dateCreated: Date.now(),
          dateUpdated: Date.now(),
          fileName: newForm.fileName,
          note: newForm.note,
        };
        setForms((prevForms) => [...prevForms, addedForm]);


        setFormToView({
          formId: docRef.id,
          formContent: "",
          formAnnotations: "",
          fileName: newForm.fileName,
          note: newForm.note,
        });

        setViewerOpen(true);
        setNewFormOpen(false);
        setIsNewForm(true);
        
        openFeedbackMessage('Form added successfully!', 'success');
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const handleEditNote = (item) => {
    db.collection("filings")
      .doc(item.itemId) // Get the specific document by its ID
      .update({
        note: editNote.note,
        dateUpdated: Date.now(), // Update the timestamp for when it was modified
      })
      .then(() => {
        setForms((prevForms) =>
          prevForms.map((form) =>
            form.id === item.itemId ? { ...form, note: editNote.note } : form
          )
        );
  
        console.log("Note successfully updated!");
        // Optionally, you can add logic here to reflect the change in your UI
        seteditNoteOpen(false);
        setFormToView((prev) => ({
          ...prev,
          note: editNote.note,
        }));
        openFeedbackMessage('Form updated successfully!', 'success');
      })
      
      .catch((error) => {
        console.error("Error updating document: ", error);
      });
  };


  const openFeedbackMessage = (message, severity) => {
    setFeedbackMessage({ open: true, message, severity });
  };
  
  const handleCloseFeedbackMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackMessage({ ...feedbackMessage, open: false });
  };


  const handleFlagged=()=>{
    setFromBuildingShowFormsOfAssociatedJobs((prev)=>!prev)
  }



  

  const parsedForms = Forms.map((application, index) => ({
    ...application,
    dateCreated: formatDate(application.dateCreated),
    dateUpdated: formatDate(application.dateUpdated),
    id: index,
    formId: application.id,
  }));

  const autoFillDetails = [
    { prefix: "app", details: applicantDetails },
    { prefix: "owner", details: ownerDetails },
    { prefix: "frep", details: filingRepDetails },
    { prefix: "", details: buildingDetails },
    { prefix: "", details: extraFieldsToAutoFill }
  ];



  // const [paginationModel, setPaginationModel] = useState({
  //   pageSize: 50,
  //   page: 0,
  // });

  const { params, updateParams } = useCustomParams();

  const pageSize = Number(params.get("pageSize")) || 50,
    page = Number(params.get("page")) || 0;



  return (
    <>
      {parsedForms.length > 0 && !viewerOpen || downloadFromForm && parsedForms.length > 0 ? (
        <div
          style={{
            height: "calc(100vh - 240px)",
            width: "100%",
          }}
        >
        {/* {(Forms.length>0 && (showLabelBuildingForms || showLabelBuildingJobsForms) ) && (
          <Box
      sx={{
        margin: "20px 0", // Space on top and bottom
        backgroundColor: "#ffffff",
        borderRadius: "12px",
        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.1)",
      }}
    >
      {showLabelBuildingForms && (
        <Typography
          variant="h6"
          component="div"
          sx={{
            backgroundColor: "#f0f4f8",
            color: "#004d7a",
            padding: "15px 20px",
            borderRadius: "8px",
            marginBottom: "16px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
          }}
        >
          Forms specific to this Building
        </Typography>
      )}
      {showLabelBuildingJobsForms && (
        <Typography
          variant="h6"
          component="div"
          sx={{
            backgroundColor: "#f0f4f8",
            color: "#004d7a",
            padding: "15px 20px",
            borderRadius: "8px",
            fontWeight: "bold",
            textAlign: "center",
            boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.08)",
          }}
        >
          Forms added under associated Jobs
        </Typography>
      )}
    </Box>
        )} */}
       {showToggleFromBuilding && (
 <Box
    sx={{
      display: "flex",
      alignItems: "center",
      marginBottom:"20px"
    }}
  >

 
     <Typography align="left" variant="h4" sx={{marginRight:"20px"}}>
     FORMS
     </Typography>

    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
        backgroundColor: "#e9e9e9",
        borderRadius: "4px",
        padding:"5px 17px",
        // marginBottom:"20px"
      }}
    >
      <Typography variant="body1">Forms of Associated Jobs</Typography>
      <Switch
        checked={fromBuildingShowFormsOfAssociatedJobs}
        onChange={handleFlagged}
        color="primary"
        size="medium" 
      />
    </Box>
  </Box>

       )}


          <div
            style={{
              display: "flex",
              height: "100%",
            }}
          >
            <div style={{ flexGrow: 1 }}>
              <DataGrid
                rows={parsedForms}
                columns={columnDefs}
                rowCount={parsedForms.length}
                onRowClick={(e) => {
                  handleOpenForm(e);
                }}
                pagination
                hideFooter
                pageSizeOptions={[10, 25, 50, 100]}
                onPaginationModelChange={(params) => {
                  updateParams({
                    page: params.page,
                    pageSize: params.pageSize,
                  });
                }}
                paginationModel={{ pageSize, page }}
                initialState={{
                  pagination: { paginationModel: { pageSize: 50 } },
                }}
                onFilterModelChange={(e) => {
                  updateParams({
                    pageSize: false,
                    page: false,
                    search: e?.items?.map((item) => item?.value).join("+"),
                  });
                }}
                slots={{
                  toolbar: GridToolbar,
                }}
              ></DataGrid>
              {Forms.length > 0 && (
                <TablePagination
                  component="div"
                  count={Forms.length}
                  page={page}
                  onPageChange={(_, e) => {
                    updateParams({
                      page: e,
                      pageIncreasing: `${e > Number(params.get("page"))}`,
                    });
                  }}
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={(e) => {
                    updateParams({
                      pageSize: `${e.target.value}`,
                      page: false,
                    });
                  }}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          left: "4rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!viewerOpen && !isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={handleNewForm}
            disabled={props.isInactive}
          >
            <AddIcon sx={{ mr: 1 }} />
            New
          </Fab>
        )}
      </Box>
      {viewerOpen && (
        <PDFViewer
          handleSave={handleSave}
          handleCloseViewer={handleCloseViewer}
          formToView={formToView}
          setLoading={setLoading}
          isNewForm={isNewForm}
          autoFillDetails={autoFillDetails}
          isInactive={isInactive}
          downloadFromForm={downloadFromForm}
         setDownloadFromForm={setDownloadFromForm}
        />
      )}
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {open?.length > 0 ? open : "Form saved successfully!"}
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={loading}
        onClose={handleCloseLoading}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={loading}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Preparing PDF
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Preparing PDF file...
            </Typography>
          </Box>
        </Fade>
      </Modal>

      <Dialog
        open={newFormOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">Add Form</DialogTitle>
        <DialogContent>
          <Autocomplete
            options={formNames}
            label="Form"
            fullWidth
            groupBy={(option) => option.agency}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => <TextField {...params} label="Form" />}
            sx={{ mb: 2, mt: 1, minWidth: "400px" }}
            onChange={(event, newValue) => {
              handleChangeNewForm(newValue);
            }}
          />

          <TextField
            label={"Note"}
            name="note"
            multiline
            minRows={4}
            onChange={(event) => {
              handleChangeNewFormNote(event);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleAddForm}>
            Add
          </Button>
          <Button
            onClick={handleNewFormClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={editNoteOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={"lg"}
      >
        <DialogTitle id="alert-dialog-title">Edit Note</DialogTitle>
        <DialogContent>
          <TextField
            label={"Note"}
            name="note"
            value={currentNote}
            multiline
            minRows={4}
            sx={{ my: 2 }}
            onChange={(event) => {
              handleChangeEditNot(event);
            }}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => handleEditNote(itemToEdit)}
          >
            Edit Note
          </Button>
          <Button
            onClick={handleEditNoteClose}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
        <Snackbar
      open={feedbackMessage.open}
      autoHideDuration={2000}
     onClose={handleCloseFeedbackMessage}
     >
     <Alert
    onClose={handleCloseFeedbackMessage}
    severity={feedbackMessage.severity}
    sx={{ width: "100%" }}
     >
    {feedbackMessage.message}
  </Alert>
</Snackbar>
    </>
  );
};

