


import React, { useContext, useEffect, useRef, useState } from "react";

 import { Box, Link, Fab, Card, Typography, IconButton,Modal,
  TextField,Button,Snackbar,} from "@mui/material/";
import Clear from "@mui/icons-material/Clear";
import Save from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  Inject,
  FormFields,
  DisplayMode,
  FormDesigner

} from "@syncfusion/ej2-react-pdfviewer";
import {db } from "config/config";
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  where,
or
} from "firebase/firestore"; 
import "../styles/editorOverride.css";
import InfiniteScrollSelect,{ fetchData } from "./shared/InfiniteScrollSelect";
import { columnSelectionComplete } from "@syncfusion/ej2-react-grids";
import "../styles/PDFViewer.css"
import firebase from "firebase/compat/app";
import MuiAlert from "@mui/material/Alert";
import { CreateUniqueDocument } from "components/shared/createUniqueDocument ";
import { Context } from "context/Wrapper";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { CurrencyBitcoinRounded } from "@mui/icons-material";
 import tableConfig from "config/advancedTableColumnsControl.json";
import { display } from "@mui/system";



const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const PDFViewer = ({
  formToView,
  handleSave,
  handleCloseViewer,
  setLoading,
  isNewForm,
  autoFillDetails,
  isInactive,
  downloadFromForm,
  setDownloadFromForm
}) => {



  const childRef = useRef();

  const [fieldArray, setFieldArray] = useState();

  // const isNewForm = true;

  const [pageNumber, setPageNumber] = useState(0);
  
  const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;


  const navigate=useNavigate()
  const context = useContext(Context);

   const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {};
     const { showAllDataFlag } = context?.state || {};
   let searchTimeout = null


  



  const [openModal, setOpenModal] = useState(false);
  const [fullName, setFullName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [address1, setaddress1] = useState('');
  const [city, setcity] = useState('');
  const [state, setstate] = useState('');
  const [ZIP, setZIP] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const [isContactSelectionVisible,setIsContactSelectionVisible]=useState(false)
  const [currentPrefix,setCurrentPrefix]=useState('')

  const [feedbackMessage, setFeedbackMessage] = useState({
    open: false,
    message: '',
    severity: 'success'
  });


  

  function extractKeyValuePairs(arr) {
    const result = [];

    arr.forEach((item) => {
      if (item.details) {
        const prefix = item.prefix;
        const details = item.details;

        for (const key in details) {
          if (details.hasOwnProperty(key)) {
            const keyValue = `${prefix}${key}`;
            const value = details[key];
            result.push({ name: keyValue, value: value, type: "Textbox" });
          }
        }
      }

    });

    return result;
  }

 
  

  const onHandleSave= () => {
   
    setLoading(true);

    var viewer = childRef.current;

  
    var formFields = viewer.retrieveFormFields()

    
    // console.log(formFields);
    // console.log(JSON.stringify(formFields))
    // console.log(extractKeyValuePairs(formFields))
 
    viewer.exportFormFieldsAsObject().then(function (formFields) {
      viewer.exportAnnotationsAsObject().then(function (annotations) {

        // console.log(formFields);
        // console.log(annotations)
       
      });
    });

    /*  if (viewer.annotationCollection.length > 0) { */
    viewer.exportAnnotationsAsObject().then(function (value) {
      handleSave(JSON.stringify(formFields), value, formToView.formId);
    });
    /*   } else {
      handleSave(JSON.stringify(formFields), "", formToView.formId);
    } */
      
  };

 

  
  const onDocumentLoaded = () => {
    const pdfViewerInstance = childRef.current;

   
 

    const formContent = formToView.formContent
      ? JSON.parse(JSON.parse(formToView.formContent))
      : "";



    
    let newFieldArray = formContent
      ? formContent.map((field) => ({
          ...field,
          filled: false,
        }))
      : null;
     
    
      listenForFormFieldChanges();
     
     if(newFieldArray==null){
       var viewer = childRef.current;
      var formFields = viewer.retrieveFormFields()
     newFieldArray= formFields.map((field) => ({
        ...field,
        filled: false,
      }))
     
     }
      // var viewer = childRef.current;
      // var formFields = viewer.retrieveFormFields()
      // const newFieldArray= formFields.map((field) => ({
      //   ...field,
      //   filled: false,
      // }))
      
      

  
    if (isNewForm || !isNewForm) {
      let autoFillFields = extractKeyValuePairs(autoFillDetails);
     


     
      let selectedFieldsToBeFilledAutomatically=newFieldArray.filter((field)=>{
      //  return  field.name=="appfullName" ||  field.name=="todaysDate"
       return   field.name=="todaysDate"
      })

      selectedFieldsToBeFilledAutomatically.forEach((item)=>{
        if(item.name==="todaysDate"){
          const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
          item.value = todayDate;
        }
        
        pdfViewerInstance.updateFormFieldsValue(item);
      })
     

    //   autoFillFields.forEach((item) => {
    //     let fieldsToFill = document.getElementsByName(item.name);
            
    //  console.log(item.name)
    //  console.log(fieldsToFill)
    //     fieldsToFill.forEach((field) => {

    //       if (field !== undefined) {
    //         field.value = item.value;
    //         console.log(field)
    //         pdfViewerInstance.updateFormFieldsValue(field);
    //       }
    //     });
    //   });
     



    autoFillFields.forEach((item) => {
      // Replace 'owner' prefix with 'app' if it exists in the field name

      //  let fieldName = item.name.startsWith('owner') ? item.name.replace(/^owner/, 'app') : item.name;
      let fieldName = item.name;
  
      // Get the form fields from the pdfViewerInstance
      const formFields = pdfViewerInstance.formFieldCollections;


      const fieldsFound = formFields.filter((formField) => formField.name === fieldName);
    
    
      fieldsFound.forEach((field) => {
          if (field !== undefined) {
              field.value = item.value;
              pdfViewerInstance.updateFormFieldsValue(field);  // Updating the field directly

          }
      });
  });
  
  
    
    }


    if (
      formToView.formAnnotations &&
      formToView.formAnnotations !== '""' &&
      formToView.formAnnotations !== ""
    ) {
      const annotations = JSON.parse(JSON.parse(formToView.formAnnotations));
      annotations && pdfViewerInstance.importAnnotation(annotations)
    }
    if (newFieldArray) {
    

      newFieldArray.forEach((item) => {

        if (document.getElementById(item.id) !== null && !item.filled) {
        
   
          // if(item.name==="todaysDate" ){
          //   const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
          //   item.value = todayDate;
          //   pdfViewerInstance.updateFormFieldsValue(item);
          //   item.filled = true; // Mark the field as filled
          // }

         

          if (item.type === "CheckBox" && item.isChecked) {
            item.value = true;
            pdfViewerInstance.updateFormFieldsValue(item);
          }
          if (item.type === "RadioButton" && item.isSelected) {
            item.value = true;
            pdfViewerInstance.updateFormFieldsValue(item);
          }

          if (item.type === "Textbox" && item.value !== "") {
            pdfViewerInstance.updateFormFieldsValue(item);
          }
          item.filled = true;
        }
      });
       
    }
   



    setFieldArray(newFieldArray);
  
   
  if(downloadFromForm){
    // var viewer = document.getElementById('container').ej2_instances[0];
    pdfViewerInstance.download();
  }

  };



  const listenForFormFieldChanges = () => {

    const viewer = childRef.current;
    const formFields = viewer.retrieveFormFields();

    formFields?.forEach((field) => {
      
      const formElement = document.getElementById(field.id);
 
      if (formElement) {

        formElement.addEventListener('change', (event) => {
          handleFieldChange(event, field);
        });
  
        formElement.addEventListener('input', (event) => {
          handleFieldChange(event, field);
        });
      }
    });



    // const pdfViewerInstance = childRef.current;
    // // Assuming pdfViewerInstance.formFieldCollections contains all the form fields in the PDF.
    // pdfViewerInstance.formFieldCollections.forEach(field => {
    //   // Ensure the form field is an input element
    //   const formElement = field.element;
      
    //   if (formElement) {
    //     // Add 'change' and 'input' event listeners directly to the form element
    //     formElement.addEventListener('change', event => handleFieldChange(event, field));
    //     formElement.addEventListener('input', event => handleFieldChange(event, field));
    //   }
    // });
    


  };


  
  const handleFieldChange = (event, field) => {
    const { value, checked, type } = event.target;
    
    // Update the field's value in your state or data structure

    setFieldArray((prevFieldArray) =>
      prevFieldArray.map((item) => {
        if (item.id === field.id) {
          return {
            ...item,
             value: type === 'checkbox' || type === 'radio' ? checked : value,
            filled: true,
          };
        }
        return item;
      })
    );



    
    // Check if the field's name matches any prefix in autoFillDetails
    const matchedPrefix = autoFillDetails.find(({ prefix }) =>
      field.name.startsWith(prefix)
    );
      // console.log(field)
    // console.log(matchedPrefix)

    if (matchedPrefix && field.name==`${matchedPrefix.prefix}lastName`) {
      clearTimeout(searchTimeout); // Clear any previous timeout
      if (value?.length >= 3) {
        // clearTimeout(searchTimeout); // Clear any previous timeout
  
        // Start a new timeout to debounce the search
        searchTimeout = setTimeout(async () => {
        
          // Call fetchData2 with the combined conditions (no pagination initially)
          const { data, count, lastVisibleDoc } = await fetchData({
            lastVisible:null,
            collectionName:'contacts',
             searchText:value,
              label:'fullName',
             conditions:
              userId && groupId && isPro && showAllDataFlag
             ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
            : userId
              ? [where("userId", "==", userId)]
              : [],

          });

         let filteredResult;
         let  filterSearchedTimeout ;
         const searchLimit = tableConfig?.searchMinLength;

         if (value?.length < searchLimit && value?.length !== 0) return;
      
      // const newArr = data
      //   ?.filter((item) =>
      //     item?.['fullName']?.toLowerCase().includes(value.toLowerCase())
      //   )

    // Filter the result to get the first match
    //  const newArr = data.find((item) =>
    //     item?.['fullName']?.toLowerCase().includes(value.toLowerCase())
    //  );


 // Normalize the search value and fullName by removing spaces
 let normalizedValue = value.replace(/\s+/g, '').toLowerCase();

 //first check for exact match (no spaces, case-insensitive)
 let newArr = data.find((item) => {
   const normalizedFullName = item?.['fullName']?.replace(/\s+/g, '').toLowerCase();
   return normalizedValue === normalizedFullName;
 });


 if (!newArr) {
 newArr = data.find((item) =>
    item?.['fullName']?.toLowerCase().includes(value.toLowerCase())
  );

} 

      
       filteredResult=newArr;
       // console.log(value)
     // console.log(filteredResult)
       if(filteredResult!=undefined || filteredResult!=''){

         handleSelectChange(true,filteredResult, matchedPrefix.prefix)
       }
          
          // console.log(filteredResult)
         
        }, 300); // Debounce with 3 second delay
      }
    }
  
 
  
  };



  const onPageChange = () => {
   
    const pdfViewerInstance = childRef.current;

   
    // fieldArray.forEach((item) => {
      
    //   if (document.getElementById(item.id) !== null && !item.filled) {

       
    //     if (item.type === "CheckBox" && item.isChecked) {
    //       item.value = true;
    //       pdfViewerInstance.updateFormFieldsValue(item);
    //     }
    //     if (item.type === "RadioButton" && item.isSelected) {
    //       item.value = true;
    //       pdfViewerInstance.updateFormFieldsValue(item);
    //     }

    //     if (item.type === "Textbox" && item.value !== "") {
    //       pdfViewerInstance.updateFormFieldsValue(item);
    //     }
    //     item.filled = true;
    //   }
    // });

    const formFields = pdfViewerInstance.formFieldCollections;
    

    fieldArray.forEach((item) => {
      // Find the form field by ID in the formFieldCollections
      const field = formFields.find((formField) => formField.id === item.id);
    
      // Check if the field exists and if the item is not yet filled
      if (field !== undefined && !item.filled) {
        // Handle CheckBox fields
        if (item.type === "CheckBox" && item.isChecked) {
          item.value = true;
          field.value = item.value;
          pdfViewerInstance.updateFormFieldsValue(field);
        }
    
        // Handle RadioButton fields
        if (item.type === "RadioButton" && item.isSelected) {
          item.value = true;
          field.value = item.value;
          pdfViewerInstance.updateFormFieldsValue(field);
        }
    
        // Handle Textbox fields
        if (item.type === "Textbox" && item.value !== "") {
          field.value = item.value;
          pdfViewerInstance.updateFormFieldsValue(field);
        }
    
        // Mark the item as filled
        item.filled = true;
      }
    });
    
   



    if (pdfViewerInstance.currentPageNumber > pageNumber) {
      // if (fieldArray) {
      //   fieldArray.forEach((item) => {
      //     if (document.getElementById(item.id) !== null && !item.filled) {
      //       if (item.type === "CheckBox" && item.isChecked) {
      //         item.value = true;
      //         pdfViewerInstance.updateFormFieldsValue(item);
      //       }
      //       if (item.type === "RadioButton" && item.isSelected) {
      //         item.value = true;
      //         pdfViewerInstance.updateFormFieldsValue(item);
      //       }

      //       if (item.type === "Textbox" && item.value !== "") {
      //         console.log(item.value)
      //         pdfViewerInstance.updateFormFieldsValue(item);
      //       }
      //       item.filled = true;
      //     }
      //   });
      // }
      
      setPageNumber(pageNumber);
      setFieldArray(fieldArray);
    }
  
   };

 

  

  function printClicked() {
    var viewer = childRef.current;

    console.log(viewer.print);
  }
  




  const downloadEnd = () => {
    if(downloadFromForm){
      handleCloseViewer()
    setDownloadFromForm(false)
    
    return;
    }
    window.setTimeout(onHandleSave(), 100);
  };



//   const handleSelectChange = (newValue, valueData, prefix) => {
//  console.log(valueData)
//  console.log(prefix)
  

//     if (newValue) {
//       const pdfViewerInstance = childRef.current;
 
//       if (valueData) {
//        console.log( Object.entries(valueData))

//         Object.entries(valueData).forEach(([key, value]) => {
//           const field = document.querySelector(`input[name="${prefix}${key}"]`);

//           console.log('field , key',field , key)
//         console.log(key)
//           console.log(value)

         

//           if (field) {
//             console.log(field.id)
//             field.value = value;
//             pdfViewerInstance.updateFormFieldsValue({ id: field.id, value: value });
//           }
//         });
//       }
//     }




//   };

  const handleSelectChange = (newValue, valueData, prefix) => {

  
    if (newValue) {
      const pdfViewerInstance = childRef.current;
      
      
      const formFields = pdfViewerInstance.retrieveFormFields();

  
      if (valueData) {
        
        for (const field of document.querySelectorAll(`input[name^="${prefix}"]`)) {
          field.value = "";
          pdfViewerInstance.updateFormFieldsValue({
            id: field.id,
            value: '',
          });
        }

        // for (const field of formFields) {
        //   if (field.name.startsWith(prefix)) {
        //     field.value = "";
        //     pdfViewerInstance.updateFormFieldsValue({
        //       id: field.id,
        //       value: "",
        //     });
        //   }
        // }



        // Object.entries(valueData).forEach(([key, value]) => {
        
        //   const matchingField = formFields.find(field => field.name === `${prefix}${key}`);
        //   if (matchingField) {
        //     // Perform the field-specific updates
        //     if (matchingField.name === "todaysDate") {
        //       const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
        //       matchingField.value = todayDate;
        //       pdfViewerInstance.updateFormFieldsValue(matchingField);
        //     }
  
        //     if (matchingField.type === "CheckBox" && value) {
        //       matchingField.value = true; 
        //       pdfViewerInstance.updateFormFieldsValue(matchingField);
        //     }
  
        //     if (matchingField.type === "RadioButton" && value) {
        //       matchingField.value = true; 
        //       pdfViewerInstance.updateFormFieldsValue(matchingField);
        //     }
  
        //     if (matchingField.type === "Textbox" && value !== "") {
        //       matchingField.value = value; 
        //       pdfViewerInstance.updateFormFieldsValue(matchingField);
        //     }
  
        //     // Mark the field as filled (if necessary in your logic)
        //     matchingField.filled = true;
        //   }
        // });



        Object.entries(valueData).forEach(([key, value]) => {
        
        
          const matchingFields = formFields.filter(field => field.name === `${prefix}${key}`);
        
          if (matchingFields.length > 0) {
            matchingFields.forEach((matchingField) => {
              if (matchingField.name === "todaysDate") {
                const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
                matchingField.value = todayDate;
                pdfViewerInstance.updateFormFieldsValue(matchingField);
              }
        
              if (matchingField.type === "CheckBox" && value) {
                matchingField.value = true; 
                pdfViewerInstance.updateFormFieldsValue(matchingField);
              }
        
              if (matchingField.type === "RadioButton" && value) {
                matchingField.value = true; 
                pdfViewerInstance.updateFormFieldsValue(matchingField);
              }
        
              if (matchingField.type === "Textbox" && value !== "") {
                matchingField.value = value; 
                pdfViewerInstance.updateFormFieldsValue(matchingField);
              }
        
              matchingField.filled = true;

              setFieldArray((prevFieldArray) =>
                prevFieldArray.map((item) => {
                  if (item.id === matchingField.id) {
                    return {
                      ...item,
                      // value: matchingField.type === 'CheckBox' || matchingField.type === 'RadioButton' ? matchingField.checked : matchingField.value,
                        value: matchingField.value,
                      filled: true,
                    };
                  }
                  return item;
                })
              );

            });

           



          }




        });
        



      }
    }
  };


  const handleOpenModal = (prefix) => {
    setCurrentPrefix(prefix)
    setFullName(""); 
    setFirstName(""); 
    setLastName(""); 
    setBusinessName(""); 
    setaddress1(""); 
    setcity(""); 
    setstate(""); 
    setZIP(""); 
    setPhoneNumber(""); 
    setEmail(""); 

    
    setOpenModal(true);
  };
  

  const handleCloseModal = () => {
    setOpenModal(false);
  };


  // const handleAddContact = async () => {
  //   try {
  //     await firebase.firestore().collection("contacts").add({
  //       fullName,
  //       firstName,
  //       lastName,
  //       businessName,
  //       phone:phoneNumber,
  //       email,
  //     });
      
  //     // Clear input fields after adding contact
  //     setFullName("");
  //     setFirstName("");
  //     setLastName("");
  //     setBusinessName("");
  //     setPhoneNumber("");
  //     setEmail("");
      
  //     // Close the modal
  //     handleCloseModal();
      
  //     // Show success feedback
  //     openFeedbackMessage('Contact added successfully!', 'success');
  //   } catch (error) {
  //     console.error("Error adding contact:", error);
  //     // Show error feedback
  //     openFeedbackMessage('Error adding contact. Please try again.', 'error');
  //   }
  // };
  
  
  const openAddedSuccessfully = async(docref) => {
  
    const docSnapshot = await getDoc(docref);
    const data = docSnapshot.data();
       
       handleSelectChange(true, data,currentPrefix)

    openFeedbackMessage('Contact added successfully!', 'success');
    handleCloseModal();
  };

  const handleAddContact = (data) => {
    CreateUniqueDocument({
      disableNavigation:true,
     openAddedSuccessfully,
       navigate,
      db,
      collectionName: "contacts",
      data: { 
        fullName,
        firstName,
        lastName,
        businessName,
        address1,
        city,
        state,
        ZIP,
        ...{phone:phoneNumber},
        email
        , userId: user.uid
        , groupId },
      uniqueFieldName: "fullName", 
      failureCallback: () => {
        console.error("Error adding document.");
        // setSubmitLoader(false);
        openFeedbackMessage('problem occured!', 'error');
      },
      duplicateCallback: () => {
        // setDuplicateOpen(true);
        // setSubmitLoader(false);
        openFeedbackMessage('Duplicate data!', 'error');
      },
      loadingCallback: () => {
        // setSubmitLoader(true);
      }
    });


  };

  
  const openFeedbackMessage = (message, severity) => {
    setFeedbackMessage({ open: true, message, severity });
  };
  
  const handleCloseFeedbackMessage = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedbackMessage({ ...feedbackMessage, open: false });
  };

//   const handleFormFieldClick = (args) => {
//   console.log("Form field clicked:", args);

//   const item = args.field; // The field object
//   const pdfViewerInstance = childRef.current; // Reference to PDF Viewer instance

//   // Handle CheckBox and RadioButton specific logic
//   if (item.type === "CheckBox" || item.type === "RadioButton") {
//     item.value = true; // Set the checked or selected state
//     item.filled = true; // Mark as filled
//   }

//   // Update the form field's value in the PDF
//   pdfViewerInstance.updateFormFieldsValue(item);
// };

  

//   const handleFormFieldChange = (args) => {
//     console.log("Form field changed:", args);
//     const item = args.field; 
//     const pdfViewerInstance = childRef.current;
//     pdfViewerInstance.updateFormFieldsValue(item);
//   };





  const names = {
    app: "Applicant",
    owner: "Owner",
    frep: "Filing Representative",
  };

  return (
    <div style={{ display: `${downloadFromForm ? "none" : ""}` }}>
      <Modal open={openModal} onClose={handleCloseModal}>
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: "white",
      padding: 20,
    }}
  >
    <h2>Add New Contact</h2>

    {/* Full Name */}
    <TextField
      label="Full Name"
      value={fullName}
      onChange={(e) => setFullName(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
      required
    />

    {/* First Name */}
    <TextField
      label="First Name"
      value={firstName}
      onChange={(e) => setFirstName(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* Last Name */}
    <TextField
      label="Last Name"
      value={lastName}
      onChange={(e) => setLastName(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* Business Name */}
    <TextField
      label="Business Name"
      value={businessName}
      onChange={(e) => setBusinessName(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* Address 1 */}
    <TextField
      label="Address 1"
      value={address1}
      onChange={(e) => setaddress1(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* City */}
    <TextField
      label="City"
      value={city}
      onChange={(e) => setcity(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* State */}
    <TextField
      label="State"
      value={state}
      onChange={(e) => setstate(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* ZIP */}
    <TextField
      label="ZIP"
      value={ZIP}
      onChange={(e) => setZIP(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* Phone Number */}
    <TextField
      label="Phone Number"
      value={phoneNumber}
      onChange={(e) => setPhoneNumber(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* Email */}
    <TextField
      label="Email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      fullWidth
      sx={{ marginBottom: "1rem" }}
    />

    {/* Add Contact Button */}
    <Button
      variant="contained"
      color="primary"
      disabled={!fullName}
      onClick={handleAddContact}
      style={{ marginTop: 20 }}
    >
      Add Contact
    </Button>

    {/* Cancel Button */}
    <Button
      variant="contained"
      color="secondary"
      onClick={handleCloseModal}
      style={{ marginTop: 20 }}
      sx={{ marginLeft: "1rem" }}
    >
      Cancel
    </Button>
  </div>
</Modal>
{/*  
          <Card
           sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      gap: 2,
                      mb: 2,
                      p: 2,
                      flexWrap: "wrap",
                    }}
            ref={(el) => {
              if (el) {
                if (
                  [...document.querySelectorAll(".autoFillSelect")].filter(
                    (el) => el.style.display !== "none"
                  ).length === 0
                ) {
                 
                  el.style.display = "none";
                } else {
                  
                  el.style.display = "flex";
                }
              }
            }}
          >
            <Typography variant="h6">
              Auto-Filling Contact Information
            </Typography>
            <p style={{fontSize:"15px"}}>
            Select a contact from a pull-down box
             to have its information auto-filled in the form. 
             Alternatively, you can type in the last name or full name of a contact in any field labelled
              <span style={{fontWeight:"700"}}> Last Name </span>in the form. As soon as you do that, the remaining fields in that section will
               be auto-filled with that contact’s information.
            </p>
            {autoFillDetails?.map(({ prefix }) => (
              <Box
                className="autoFillSelect"
                sx={{ flex: 1 }}
                ref={(el) => {
                  if (el) {
                    if (!document.querySelector(`input[name="${prefix}lastName"]`)) {
                      el.style.display = "none";
                    } else {
                  
                      el.style.display = "block";
                    }
                  }
                }}
              >
                   <div style={{position:"relative"}}>
                    <div className="addFromAutofillContactSection" onClick={()=>handleOpenModal(prefix)}>
                         <AddIcon />
                    </div>
                <InfiniteScrollSelect
                  collectionName="contacts"
                  label="fullName"
                  inputLabel={`Select ${names?.[prefix]}`}
                  onChange={(event, newValue, valueData) =>
                    handleSelectChange(newValue, valueData, prefix)
                  }
                />
                </div>
              </Box>
            ))}
          </Card>
       

          <form id="pdfComponent">
            <PdfViewerComponent
              id="container"
              documentPath={window.location.origin + "/pdfs/" + formToView.fileName}
              resourceUrl="https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib"
              enablePrint={true}
              style={{ height: "calc(100vh - 240px)" }}
              signatureDialogSettings={{
                displayMode: DisplayMode.Draw | DisplayMode.Text | DisplayMode.Upload,
                hideSaveSignature: true,
              }}
              documentLoad={onDocumentLoaded}
              pageChange={onPageChange}
              ref={childRef}
              downloadEnd={downloadEnd}
              toolbarSettings={{
                showTooltip: true,
                toolbarItems: [
                  "UndoRedoTool", "PageNavigationTool", "MagnificationTool", "PanTool",
                  "SelectionTool", "CommentTool", "AnnotationEditTool", "FreeTextAnnotationOption",
                  "InkAnnotationOption", "ShapeAnnotationOption", "StampAnnotation",
                  "SignatureOption", "SearchOption",
                  ...(isInactive ? [] : ["PrintOption"]),
                  ...(isInactive ? [] : ["DownloadOption"]),
                ],
                annotationToolbarItems: [
                  "HighlightTool", "UnderlineTool", "StrikethroughTool", "ColorEditTool",
                  "OpacityEditTool", "AnnotationDeleteTool", "StampAnnotationTool",
                  "HandWrittenSignatureTool", "InkAnnotationTool", "ShapeTool", "CalibrateTool",
                  "StrokeColorEditTool", "ThicknessEditTool", "FreeTextAnnotationTool",
                  "FontFamilyAnnotationTool", "FontSizeAnnotationTool", "FontStylesAnnotationTool",
                  "FontAlignAnnotationTool", "FontColorAnnotationTool", "CommentPanelTool",
                ],
              }}
            >
              <Inject
                services={[
                  Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView,
                  ThumbnailView, Print, TextSelection, TextSearch, FormFields,
                ]}
              />
            </PdfViewerComponent>
          </form> 
     


 */}
<Box
  sx={{
    display: "flex",
    // flexDirection:"column",
    flexDirection: {
      xs: "column", // Column layout for extra-small and small screens
      md: "row",    // Row layout for medium and larger screens
    },
    gap: 2, // Adds space between the sections
    width: "100%",
  }}
>
  {/* Contact Selection (Left side) */}
  <Box
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      transition: "width 0.3s ease",
    }}
  >
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 2,
        mb: 2,
        p: 2,
      }}
      ref={(el) => {
        if (el) {
          const anyVisible = [...document.querySelectorAll(".autoFillSelect")]
            .some((el) => el.style.display !== "none");
          el.style.display = anyVisible ? "flex" : "none";
        }
      }}
    >
      <Typography variant="h6">
        Auto-Filling Contact Information
      </Typography>
      <p style={{ fontSize: "15px" }}>
        Select a contact from a pull-down box to have its information auto-filled
        in the form. Alternatively, you can type in the last name or full name
        of a contact in any field labelled <span style={{ fontWeight: "700" }}> Last Name </span>
        in the form. As soon as you do that, the remaining fields in that section will
        be auto-filled with that contact’s information.
      </p>
      {/* {autoFillDetails?.map(({ prefix }) => (
              <Box
                className="autoFillSelect"
                sx={{ flex: 1 }}
                ref={(el) => {
                  if (el) {
                    if (!document.querySelector(`input[name="${prefix}lastName"]`) && !document.querySelector(`input[name="${prefix}fullName"]`)) {
                      el.style.display = "none";
                    } else {
                      el.style.display = "block";
                    }
                  }
                }}
              >
                   <div style={{position:"relative"}}>
                    <div className="addFromAutofillContactSection" onClick={()=>handleOpenModal(prefix)}>
                         <AddIcon />
                    </div>
                <InfiniteScrollSelect
                  collectionName="contacts"
                  label="fullName"
                  inputLabel={`Select ${names?.[prefix]}`}
                  onChange={(event, newValue, valueData) =>
                    handleSelectChange(newValue, valueData, prefix)
                  }
                />
                </div>
              </Box>
            ))} */}


{/* 
{autoFillDetails?.map(({ prefix }) => (
  <Box
    className="autoFillSelect"
    sx={{ flex: 1 }}
    ref={(el) => {
      if (el) {
        const formFields = childRef.current?.formFieldCollections || [];
        let hasField = formFields.some(
          (field) =>
            field.name === `${prefix}lastName` || field.name === `${prefix}fullName`
        );
        el.style.display = hasField ? "block" : "none";
      
      }
    }}
  >
    <div style={{ position: "relative" }}>
      <div
        className="addFromAutofillContactSection"
        onClick={() => handleOpenModal(prefix)}
      >
        <AddIcon />
      </div>
      <InfiniteScrollSelect
        collectionName="contacts"
        label="fullName"
        inputLabel={`Select ${names?.[prefix]}`}
        onChange={(event, newValue, valueData) =>
          handleSelectChange(newValue, valueData, prefix)
        }
      />
    </div>
  </Box>
))} */}
{autoFillDetails?.map(({ prefix }) => (
  <Box
    className="autoFillSelect"
    sx={{ flex: 1 }}
    ref={(el) => {
      if (el) {
        const formFieldCollections = childRef.current?.formFieldCollections;
       
        if (Array.isArray(formFieldCollections)) {
          const hasField = formFieldCollections.some(
            (field) =>
              field.name === `${prefix}lastName` || field.name === `${prefix}fullName`
          );
          el.style.display = hasField ? "block" : "none";
        } 
        else if (formFieldCollections && typeof formFieldCollections === "object") {
          const fields = Object.values(formFieldCollections);
          const hasField = fields.some(
            (field) =>
              field.name === `${prefix}lastName` || field.name === `${prefix}fullName`
          );
          el.style.display = hasField ? "block" : "none";
        } 
       
        else {
          // console.warn("formFieldCollections is not iterable or does not exist.");
          el.style.display = "none";
        }
      }
    }}
  >
    <div style={{ position: "relative" }}>
      <div
        className="addFromAutofillContactSection"
        onClick={() => handleOpenModal(prefix)}
      >
        <AddIcon />
      </div>
      <InfiniteScrollSelect
        collectionName="contacts"
        label="fullName"
        inputLabel={`Select ${names?.[prefix]}`}
        onChange={(event, newValue, valueData) =>
          handleSelectChange(newValue, valueData, prefix)
        }
      />
    </div>
  </Box>
))}

{childRef.current?.formFieldCollections && (() => {
  const formFieldCollections = childRef.current.formFieldCollections;
  const fields = Object.values(formFieldCollections);
  const sections = new Set();

  fields.forEach(field => {
    const sectionMatch = field.name?.match(/^sec\d+/);
    if (sectionMatch) {
      sections.add(sectionMatch[0]);
    }
  });

 
  const sectionArray = Array.from(sections).sort((a, b) => parseInt(a.slice(3)) - parseInt(b.slice(3)));

  return (
    <>
      {sectionArray.map((section, index) => (
        <div key={index} style={{ position: "relative" }}>
          <div
            className="addFromAutofillContactSection"
            onClick={() => handleOpenModal(section)}
          >
            <AddIcon />
          </div>
          <InfiniteScrollSelect
            collectionName="contacts"
            label="fullName"
            inputLabel={`Auto-fill section ${section.slice(3)}`}
            onChange={(event, newValue, valueData) =>
              handleSelectChange(newValue, valueData, section)
            }
          />
        </div>
      ))}
    </>
  );
})()}





    </Card>
  </Box>

  {/* PDF Viewer (Right side) */}
  <Box sx={{ flex: 3 }}>
    <form id="pdfComponent">
      <PdfViewerComponent
        id="container"
        documentPath={window.location.origin + "/pdfs/" + formToView.fileName}
        resourceUrl="https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib"
        enablePrint={true}
        style={{ height: "calc(100vh - 240px)" }}
        signatureDialogSettings={{
          displayMode: DisplayMode.Draw | DisplayMode.Text | DisplayMode.Upload,
          hideSaveSignature: true,
        }}
        documentLoad={onDocumentLoaded}
  //       formFieldClick={handleFormFieldClick}
  // formFieldChange={handleFormFieldChange}
        pageChange={onPageChange}
        ref={childRef}
        downloadEnd={downloadEnd}
        toolbarSettings={{
          showTooltip: true,
          toolbarItems: [
            "UndoRedoTool", "PageNavigationTool", "MagnificationTool", "PanTool",
            "SelectionTool", "CommentTool", "AnnotationEditTool", "FreeTextAnnotationOption",
            "InkAnnotationOption", "ShapeAnnotationOption", "StampAnnotation",
            "SignatureOption", "SearchOption",
            ...(isInactive ? [] : ["PrintOption"]),
            ...(isInactive ? [] : ["DownloadOption"]),
          ],
          annotationToolbarItems: [
            "HighlightTool", "UnderlineTool", "StrikethroughTool", "ColorEditTool",
            "OpacityEditTool", "AnnotationDeleteTool", "StampAnnotationTool",
            "HandWrittenSignatureTool", "InkAnnotationTool", "ShapeTool", "CalibrateTool",
            "StrokeColorEditTool", "ThicknessEditTool", "FreeTextAnnotationTool",
            "FontFamilyAnnotationTool", "FontSizeAnnotationTool", "FontStylesAnnotationTool",
            "FontAlignAnnotationTool", "FontColorAnnotationTool", "CommentPanelTool",
          ],
        }}
      >
        <Inject
          services={[
            Toolbar, Magnification, Navigation, Annotation, LinkAnnotation, BookmarkView,
            ThumbnailView, Print, TextSelection, TextSearch, FormFields,
          ]}
        />
      </PdfViewerComponent>
    </form>
  </Box>
</Box>




      {/* Action Buttons */}
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          zIndex: 1000,
          "& > :not(style)": { m: 1 },
        }}
      >
        {!isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={onHandleSave}
            sx={{ mr: 1 }}
            disabled={isInactive}
          >
            <Save sx={{ mr: 1 }} />
            Save
          </Fab>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          variant="extended"
          onClick={handleCloseViewer}
        >
          <Clear sx={{ mr: 1 }} />
          Close
        </Fab>
      </Box>
      
      <Snackbar
  open={feedbackMessage.open}
  autoHideDuration={2000}
  onClose={handleCloseFeedbackMessage}
>
  <Alert
    onClose={handleCloseFeedbackMessage}
    severity={feedbackMessage.severity}
    sx={{ width: "100%" }}
  >
    {feedbackMessage.message}
  </Alert>
</Snackbar>
    </div>
  );
};

export default PDFViewer;










